import CanastaStorage from "./storage";
import { NextRouter } from "next/router";
import { CanastaFlowType, PageConfig } from "../domain/entities/FlowCanasta";

export const TOTAL_STEPS = 3;

const CanastaFlow: CanastaFlowType = {
  start: "/login",
  pages: {
    "/login": {
      next: () => "/purchase/confirm",
    },
    "/login/reset-password": {
      next: () => "/purchase/confirm",
    },
    "/welcome": {
      next: () => "/purchase/confirm",
      prev: () => "/login",
    },
    "/home/subscribed": {},
    "/home/not-subscribed": {},
    "/success/thank-you": {},
    "/payment/method": {
      step: 3,
      next: (_basket: any) => "/payment/processing",
      prev: (_basket: any) => "/purchase/shipping",
    },
    "/payment/cash": {
      step: 3,
      next: (_basket: any) => "/success/thank-you",
      prev: (_basket: any) => "/payment/method",
    },
    "/purchase/include": {
      step: 1,
      next: (_basket: any) => "/purchase/confirm",
      prev: () => "/login",
    },
    "/purchase/confirm": {
      step: 2,
      next: (_basket: any) => "/purchase/shipping",
      prev: (basket: any) => "/purchase/include",
    },
    "/purchase/shipping": {
      step: 3,
      next: (_basket: any) => "/payment/method",
      prev: (_basket: any) => "/purchase/confirm",
    },
  },
};

export const routeStart = (router: NextRouter, logout: boolean = false) => {
  router.push({
    pathname: CanastaFlow.start,
    query: router.query,
  });
  // We clear local storage, this applies for expired user token.
  if (logout) localStorage.clear();
};

export const routePurchaseStart = (router: NextRouter) => {
  router.push({
    pathname: "/purchase/include",
    query: router.query,
  });
};

export const routeNext = (router: NextRouter, isLoggedIn: boolean = true) => {
  const pageConfig = CanastaFlow.pages[router.pathname] as PageConfig;
  if (isLoggedIn && pageConfig.next) {
    router.push({
      pathname: pageConfig.next(CanastaStorage.get()),
      query: router.query,
    });
  } else {
    routeStart(router);
  }
};

export const routePrev = (router: NextRouter) => {
  const pageConfig = CanastaFlow.pages[router.pathname] as PageConfig;
  if (pageConfig.prev) {
    router.push({
      pathname: pageConfig.prev(CanastaStorage.get()),
      query: router.query,
    });
  }
};

export default CanastaFlow;
